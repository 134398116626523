<template>
    <list-table-layout 
        v-if="loaded"
        :header="`Work Orders (${workOrders.length})`"
        :columns="columns"
        :model="WorkOrderModel"
        :records="workOrders"
        >
    </list-table-layout>
</template>

<script>
import { ref } from '@vue/reactivity';
import ListTableLayout from '../../components/layouts/ListTableLayout.vue'

import WorkOrderModel from '../../models/WorkOrderModel';
import WorkOrderStatusType from '../../models/WorkOrderStatusType';
import store from '../../store/store';

const columns = {
    'workOrderNumber' : {
        title : "Work order #",
        sortable: true,
        link: '/work-orders/'
    },
    'status' : {
        title : "Status",
        sortable: true,
        options: WorkOrderStatusType
    },
    'serviceRequest.id': {
        title: "SR #",
        sortable: 'acceptedIssues.issue.serviceRequest.id',
        link: '/service-history/'
    },
    'serviceRequest.invoice.invoiceNumber' : {
        title : "Invoice #",
        sortable: 'acceptedIssues.issue.serviceRequest.invoice.invoiceNumber',
        link: '/invoices/',
        NA: 'Not invoiced'
    },
    'serviceRequest.invoice.invoiceDate' : {
        title: "Invoice date",
        sortable: 'acceptedIssues.issue.serviceRequest.invoice.invoiceDate',
        NA: '--'
    },
    'serviceRequest.invoice.purchaseOrderNumber' : {
        title: "PO Number",
        sortable: 'acceptedIssues.issue.serviceRequest.invoice.purchaseOrderNumber',
        NA: '--'
    },
    'estimateTotal' : {
        title: "Amount",
        format: 'money'
    },

}


export default {
    components: {
        ListTableLayout,
    },
    setup(){
        const promises = [];
        const loaded = ref(false);

        store.commit("setupCollection", WorkOrderModel);
        const workOrders = store.state.AppData[WorkOrderModel.name].keys;
        promises.push(store.dispatch('getSeries', WorkOrderModel));

        Promise.all(promises).then(() => {
            loaded.value = true;
        });

        return {workOrders, loaded, WorkOrderModel, columns}

    },
}
</script>
<style lang="scss">

@import "../../assets/scss/variables.scss";

</style>